import { Route, Routes } from "react-router-dom";
import router from "constant/router";
import React, { Suspense } from "react";
import LoadingSpin from "component/base/Loading";

const Home = React.lazy(() => import("./home"));
const Contact = React.lazy(() => import("./contact"));
const TobeExchange = React.lazy(() => import("./ToBeExchange"));
const ToBeHealthCare = React.lazy(() => import("./ToBeHealthCare"));
const ToBeWallet = React.lazy(() => import("./ToBeWallet"));
const ToBeWeb3 = React.lazy(() => import("./ToBeWeb3"));
const ToBeGaming = React.lazy(() => import("./ToBeGaming"));
const ToBePageText = React.lazy(() => import("./ToBePageText"));
const ToBeNFT = React.lazy(() => import("./ToBeNFT"));
const ToBeScan = React.lazy(() => import("./ToBeScan"));
const ToBeFarm = React.lazy(() => import("./ToBeFarm"));

export default (
  <Suspense fallback={<LoadingSpin />}>
    <Routes>
      <Route path={router.home} element={<Home />} />
      <Route path={router.contact} element={<Contact />} />
      <Route path={router.ToBeExchange} element={<TobeExchange />} />
      <Route path={router.ToBeHealthCare} element={<ToBeHealthCare />} />
      <Route path={router.ToBeWallet} element={<ToBeWallet />} />
      <Route path={router.ToBeGaming} element={<ToBeGaming />} />
      <Route path={router.ToBeWeb3} element={<ToBeWeb3 />} />
      <Route path={router.ToBeNFT} element={<ToBeNFT />} />
      <Route path={router.ToBeScan} element={<ToBeScan />} />
      <Route path={router.ToBeFarm} element={<ToBeFarm />} />
      <Route path={router.ToBePageText} element={<ToBePageText />} />
    </Routes>
  </Suspense>
);
