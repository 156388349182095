const routes = {
  home: "/",
  contact: "/contact",
  ToBeExchange: "/tobe-exchange",
  ToBeSwap: "/tobe-swap",
  ToBeHealthCare: "/tobe-health-care",
  ToBeWallet: "/tobe-wallet",
  project: "/project",
  ToBeWeb3: "/tobe-web3",
  ToBeGaming: "/tobe-gaming",
  ToBeNFT: "/tobe-nft",
  ToBeScan: "/tobe-scan",
  ToBeFarm: "/tobe-farm",
  ToBePageText: "/tobe-is-corrected-page",
};

export default routes;
